import * as React from 'react';
import {Helmet} from 'react-helmet';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';
import Footer from '@components/footer/footer';

import {Container, Row, Col} from 'react-bootstrap';

const CustomPageSingle = ({pageContext}) => {
  const customPage = pageContext.customPage;

  return (
    <Layout>
      <Helmet>
        <title>{customPage.name} – Studenckie Koła Naukowe AGH</title>
        <meta property="og:title" content={`${customPage.name} – Studenckie Koła Naukowe AGH`} />
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />
      <div className="custom-page pt-5">
        <Container className="py-xl">
          <Row>
            <Col lg={10} xl={8}>
              <h1>{customPage.name}</h1>
              <div className="custom-page__content">{documentToReactComponents(customPage.content)}</div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </Layout>
  );
};

export default CustomPageSingle;
